import React from 'react';
import PropTypes from 'prop-types';
import { StyledWrapper, StyledName, StyledPrice } from './PriceListElement.styled';

const PriceListElement = ({ name, price }) => (
  <StyledWrapper>
    <StyledName>{name}</StyledName>
    <StyledPrice>{price}</StyledPrice>
  </StyledWrapper>
);

PriceListElement.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
};

export default PriceListElement;
