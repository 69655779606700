import styled from 'styled-components';
import { fontWeight, colors, breakPoints } from 'utils/variables';

export const StyledWrapper = styled.tr`
  border-bottom: 0.1rem solid ${colors.frozenSilver};

  th {
    padding-top: 2.9rem;
    padding-bottom: 2.7rem;
  }
`;

export const StyledName = styled.th`
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: ${fontWeight.light};
  color: ${colors.veryDarkBlue};
  text-align: left;
  padding-left: 1rem;

  @media (min-width: ${breakPoints.desktop}) {
    padding-left: 4rem;
  }
`;

export const StyledPrice = styled.th`
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: ${fontWeight.bold};
  color: ${colors.veryDarkBlue};
  text-align: right;
  padding-right: 1rem;
  min-width: 3rem;

  @media (min-width: ${breakPoints.desktop}) {
    padding-right: 4rem;
  }
`;
