import React from 'react';
import Layout from 'templates/Layout/Layout';
import ContentData from 'content/priceList-informationOffice.json';
import InformationOffice from 'components/_shared/InformationOffice/InformationOffice';
import { FormContainer, Individually } from 'components/PriceList';
import PageHeading from 'components/_shared/PageHeading/PageHeading';
import ContactForm from 'components/_shared/ContactForm/ContactForm';

const PriceList = () => (
  <Layout metaTitle="Cennik usług" metaDescription="Nasze ceny.">
    <PageHeading text="Cennik" />
    <Individually />
    <FormContainer>
      <ContactForm title="Wyślij nam zapytanie ofertowe" />
    </FormContainer>
    <InformationOffice contentData={ContentData} />
  </Layout>
);

export default PriceList;