import styled from 'styled-components';
import { breakPoints } from 'utils/variables';
import Container from 'components/_shared/Container/Container';

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledContainer = styled(Container)`
  position: relative;
  padding: 0 0 5rem 0;
  max-width: 75rem;
  margin: auto;

  @media (min-width: ${breakPoints.tablet}) {
    padding: 0rem 0 8rem 0;
    max-width: unset;
  }

  @media (min-width: ${breakPoints.desktop}) {
    padding: 1.6rem 0 14.4rem 0;
  }
`;

export const StyledShape1 = styled.div`
  display: none;
  pointer-events: none;

  @media (min-width: ${breakPoints.desktop}) {
    display: inline-block;
    position: absolute;
    left: -42rem;
    top: -10rem;
    transform: rotate(270deg);
    height: 33.651rem;
    width: 45.629rem;
  }
`;

export const StyledShape2 = styled.div`
  display: none;
  pointer-events: none;

  @media (min-width: ${breakPoints.desktop}) {
    display: inline-block;
    position: absolute;
    left: -17.6rem;
    top: -15.9rem;
    transform: rotate(270deg);
    height: 30.5rem;
    width: 22.5rem;
  }
`;
