import styled from 'styled-components';
import { breakPoints, fontWeight, colors } from 'utils/variables';
import hexToRGBA from 'utils/hexToRGBA';
import Container from 'components/_shared/Container/Container';

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledContainer = styled(Container)`
  position: relative;
  padding: 6rem 0 5rem 0;
  max-width: 80rem;
  margin: auto;

  @media (min-width: ${breakPoints.tablet}) {
    padding: 6rem 0 8rem 0;
    max-width: unset;
  }

  @media (min-width: ${breakPoints.desktop}) {
    padding: 5.3rem 0 16rem 0;
  }
`;

export const StyledShape1 = styled.div`
  display: none;
  pointer-events: none;

  @media (min-width: ${breakPoints.desktop}) {
    display: inline-block;
    position: absolute;
    right: -12.1rem;
    top: 1rem;
    width: 43rem;
    height: 43rem;
  }
`;

export const StyledText = styled.p`
  font-size: 1.8rem;
  line-height: 2.8rem;
  font-weight: ${fontWeight.medium};
  color: ${colors.grayCobalt};
  max-width: 64.6rem;
  margin: auto;
  text-align: center;
  margin-bottom: 8rem;

  @media (min-width: ${breakPoints.desktop}) {
    margin-bottom: 17.6rem;
  }
`;

export const StyledDescription = styled.span`
  display: block;
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: ${fontWeight.light};
  color: ${colors.veryDarkBlue};
  margin-bottom: 2rem;
`;

export const StyledPriceListHeading = styled.thead`
  background-color: ${colors.butter};
  border: none;
`;

export const StyledPriceListHeadingElement = styled.th`
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: ${fontWeight.medium};
  color: ${colors.grayCobalt};
  padding: 1rem;
  text-align: right;

  @media (min-width: ${breakPoints.desktop}) {
    padding: 1rem 3rem;
    font-size: 1.8rem;
    line-height: 2.8rem;
  }

  &:first-child {
    text-align: left;
  }

  &:last-child {
    min-width: 6.5rem;
  }
`;

export const StyledPriceListTable = styled.table`
  position: relative;
  border-collapse: collapse;
  box-shadow: 0 0 0.2rem ${hexToRGBA(colors.black, 0.2)};
  width: 100%;
  background-color: ${colors.white};
`;
