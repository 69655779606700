import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import StaticShape from 'components/_shared/AloneShapes/StaticShape/StaticShape';
import { colors } from 'utils/variables';
import { StyledWrapper, StyledContainer, StyledShape1, StyledShape2 } from './FormContainer.styled';

const AnimatedShape =  loadable(() => import('components/_shared/AloneShapes/AnimatedShape/AnimatedShape'));

const FormContainer = ({ children }) => (
  <StyledWrapper>
    <StyledContainer>
      <StyledShape1>
        <StaticShape
          name="FormContainer-1"
          originalWidth={420}
          originalHeight={319}
          fill={colors.peachMilk}
          path=" M 1.192 4.244 C -0.116 8.363 -0.989 13.148 2.125 14.934 C 5.24 16.721 9.852 16.155 11.721 13.201 C 13.591 10.247 18.106 10.211 20.297 6.235 C 22.487 2.26 19.115 -0.48 16.275 0.07 C 13.435 0.621 2.501 0.126 1.192 4.244 Z "
        />
      </StyledShape1>
      <StyledShape2>
        <AnimatedShape
          name="FormContainer-2"
          originalWidth={226}
          originalHeight={306}
          paths={[
            ' M 0.039 11.285 C -0.05 10.045 0.041 11.557 0.039 10.18 C 0.032 6.212 3.218 0.048 5.628 0.048 C 6.279 0.048 7.095 -0.06 8.822 0.048 C 9.869 0.114 10.596 0.657 11.187 2.074 C 11.303 2.351 11.329 3.223 11.268 3.844 C 11.126 5.27 10.198 5.828 9.721 6.841 C 9.36 7.607 9.605 7.839 9.87 8.589 C 10.412 10.123 10.469 10.282 10.469 11.636 C 10.469 12.929 10.522 13.178 9.271 14.433 C 8.184 15.523 6.336 15.272 6.027 15.282 C 5.407 15.301 5.304 15.282 4.28 15.282 C 2.715 15.282 0.251 14.251 0.039 11.285 Z ',
            ' M 3.987 2.685 C 4.554 2.606 3.863 2.686 4.493 2.685 C 6.308 2.678 9.128 5.498 9.128 7.631 C 9.128 8.207 9.177 8.929 9.128 10.457 C 9.098 11.384 8.849 12.027 8.201 12.55 C 8.074 12.653 7.675 12.676 7.391 12.622 C 6.739 12.496 6.484 11.675 6.02 11.253 C 5.67 10.933 5.564 11.15 5.22 11.385 C 4.519 11.864 4.446 11.915 3.826 11.915 C 3.235 11.915 3.121 11.962 2.547 10.854 C 2.048 9.893 2.163 8.257 2.158 7.984 C 2.15 7.435 2.158 7.344 2.158 6.438 C 2.158 5.053 2.63 2.872 3.987 2.685 Z ',
            ' M 2.965 0.053 C 3.881 -0.068 2.765 0.056 3.782 0.053 C 6.712 0.043 11.265 4.357 11.265 7.62 C 11.265 8.502 11.344 9.607 11.265 11.945 C 11.216 13.363 10.815 14.347 9.768 15.147 C 9.564 15.304 8.92 15.339 8.461 15.257 C 7.408 15.064 6.996 13.808 6.248 13.162 C 5.682 12.673 5.511 13.005 4.957 13.364 C 3.824 14.098 3.706 14.175 2.706 14.175 C 1.751 14.175 1.567 14.247 0.64 12.553 C -0.165 11.081 0.021 8.579 0.013 8.161 C -0.001 7.321 0.013 7.182 0.013 5.795 C 0.013 3.676 0.775 0.34 2.965 0.053 Z ',
          ]}
        />
      </StyledShape2>
      {children}
    </StyledContainer>
  </StyledWrapper>
);

FormContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default FormContainer;
