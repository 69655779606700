import React from 'react';
import loadable from '@loadable/component';
import ImageSVG from 'images/aboutUs-individually-1.inline.svg';
import { colors } from 'utils/variables';
import {
  StyledWrapper,
  StyledContainer,
  StyledImage,
  StyledContent,
  StyledTitle,
  StyledDescription,
  StyledShape1,
  StyledShape2,
} from './Individually.styled';

const AnimatedShape =  loadable(() => import('components/_shared/AloneShapes/AnimatedShape/AnimatedShape'));

const Individually = () => (
  <StyledWrapper>
    <StyledContainer>
      <StyledShape1>
        <AnimatedShape
          name="Individually-1"
          originalWidth={1395}
          originalHeight={876}
          fill={colors.peachMilk}
          paths={[
            ' M 53.133 41.733 C 63.288 33.395 73.431 22.636 68.436 12.925 C 63.441 3.215 50.735 -2.977 41.077 1.463 C 31.419 5.904 19.836 -1.479 7.668 5.033 C -4.5 11.546 -0.426 24.115 7.733 27.413 C 15.891 30.71 42.979 50.071 53.133 41.733 Z ',
          ]}
        />
      </StyledShape1>
      <StyledShape2>
        <AnimatedShape
          name="Individually-2"
          originalWidth={601}
          originalHeight={643}
          paths={[
            ' M 18.647 9.072 C 22.334 9.608 26.492 10.362 28.069 12.632 C 28.972 13.93 29 14.655 29 16.565 C 29 18.297 25.152 24.867 23.417 26.313 C 20.318 28.894 17.172 31 10.76 31 C 1.002 31 1.001 26.324 1 25.907 C 0.998 24.77 1.432 24.24 3.874 22.038 C 4.565 21.414 5.143 20.06 4.695 18.998 C 3.666 16.565 0.893 14.359 2.013 12.978 C 3.002 11.759 4.05 10.893 5.701 10.174 C 6.996 9.61 8.586 9.054 11.319 9.054 C 13.698 9.054 17.611 8.921 18.647 9.072 Z ',
            ' M 11.111 32.045 C 7.154 31.261 2.692 30.159 0.999 26.843 C 0.03 24.945 0 23.886 0 21.095 C 0 18.564 4.13 8.963 5.992 6.85 C 9.318 3.077 12.694 0 19.575 0 C 30.048 0 30.049 6.834 30.05 7.443 C 30.052 9.104 29.586 9.879 26.966 13.097 C 26.224 14.008 25.604 15.988 26.085 17.539 C 27.189 21.095 30.165 24.319 28.963 26.336 C 27.901 28.118 26.777 29.384 25.005 30.434 C 23.615 31.258 21.909 32.071 18.976 32.071 C 16.422 32.071 12.223 32.265 11.111 32.045 Z ',
          ]}
        />
      </StyledShape2>
      <StyledImage as={ImageSVG} />
      <StyledContent>
        <StyledTitle>Ceny usług ustalamy indywidualnie</StyledTitle>
        <StyledDescription>
          Rodzaj prowadzonej dokumentacji rachunkowej, liczba dokumentów, rodzaj prowadzonej działalności gospodarczej oraz wiele innych czynników
          wpływa na ostateczną wycenę naszych usług. <br />
          Dlatego, w tej sprawie, najlepiej skontaktować się z nami wysyłając <strong>Zapytanie Ofertowe</strong> lub poprzez kontakt telefoniczny.
        </StyledDescription>
      </StyledContent>
    </StyledContainer>
  </StyledWrapper>
);

export default Individually;
