import React from 'react';
import loadable from '@loadable/component';
import ContentData from 'content/priceList-priceList.json';
import { colors } from 'utils/variables';
import {
  StyledWrapper,
  StyledContainer,
  StyledShape1,
  StyledText,
  StyledDescription,
  StyledPriceListTable,
  StyledPriceListHeading,
  StyledPriceListHeadingElement,
} from './PriceList.styled';
import PriceListElement from './PriceListElement/PriceListElement';

const AnimatedShape =  loadable(() => import('components/_shared/AloneShapes/AnimatedShape/AnimatedShape'));

const getPosition = (position) => <PriceListElement key={position.name} name={position.name} price={position.price} />;

const PriceList = () => (
  <StyledWrapper>
    <StyledContainer>
      <StyledShape1>
        <AnimatedShape
          name="PriceList-2"
          originalWidth={430}
          originalHeight={430}
          fill={colors.peachMilk}
          paths={[
            ' M 1.632 5.84 L 11.893 0.914 C 13.38 0.2 15.225 0.799 16.01 2.25 L 21.141 11.737 C 21.925 13.188 21.355 14.946 19.868 15.66 L 9.607 20.586 C 8.12 21.3 6.275 20.701 5.49 19.25 L 0.359 9.763 C -0.425 8.312 0.145 6.554 1.632 5.84 Z ',
            ' M 0.831 13.726 L 4.603 2.987 C 5.15 1.43 6.878 0.549 8.459 1.02 L 18.795 4.1 C 20.376 4.572 21.216 6.218 20.669 7.774 L 16.897 18.513 C 16.35 20.07 14.622 20.951 13.041 20.48 L 2.705 17.4 C 1.124 16.928 0.284 15.282 0.831 13.726 Z ',
          ]}
        />
      </StyledShape1>
      <StyledText dangerouslySetInnerHTML={{ __html: ContentData.text }} />
      <StyledDescription dangerouslySetInnerHTML={{ __html: ContentData.description }} />
      <StyledPriceListTable cellspacing="0" cellpadding="0">
        <StyledPriceListHeading>
          <tr>
            <StyledPriceListHeadingElement>Nazwa usługi</StyledPriceListHeadingElement>
            <StyledPriceListHeadingElement>Cena od</StyledPriceListHeadingElement>
          </tr>
        </StyledPriceListHeading>
        <tbody>{ContentData.positions.map(getPosition)}</tbody>
      </StyledPriceListTable>
    </StyledContainer>
  </StyledWrapper>
);

export default PriceList;
