import styled from 'styled-components';
import Image from 'components/_shared/Image/Image';
import { fontWeight, colors, breakPoints } from 'utils/variables';
import Container from 'components/_shared/Container/Container';

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledContainer = styled(Container)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 6rem 0;
  flex-direction: column-reverse;

  @media (min-width: ${breakPoints.tablet}) {
    flex-direction: row;
    margin-top: 19.7rem;
    padding: 0 0 20rem 0;
  }
`;

export const StyledContent = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: 3rem;
  width: 100%;
  text-align: center;
  align-items: center;

  @media (min-width: ${breakPoints.tablet}) {
    align-items: flex-end;
    text-align: right;
    margin-top: 13.9rem;
  }
`;

export const StyledTitle = styled.h2`
  padding: 0;
  font-size: 2.4rem;
  line-height: 3.6rem;
  font-weight: ${fontWeight.medium};
  color: ${colors.darkBlue};
  margin: 0 0 2rem 0;
  max-width: 50rem;

  @media (min-width: ${breakPoints.tablet}) {
    max-width: 32rem;
    margin-bottom: 3.1rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    max-width: 45.5rem;
    font-size: 3.2rem;
    line-height: 4.8rem;
  }
`;

export const StyledDescription = styled.span`
  font-size: 1.4rem;
  line-height: 3.2rem;
  font-weight: ${fontWeight.light};
  color: ${colors.grayCobalt};
  margin-bottom: 3rem;
  max-width: 50rem;

  @media (min-width: ${breakPoints.tablet}) {
    max-width: 32rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    max-width: 45.5rem;
    font-size: 1.6rem;
    line-height: 3.8rem;
  }
`;

export const StyledShape1 = styled.div`
  display: none;
  pointer-events: none;

  @media (min-width: ${breakPoints.tablet}) {
    display: inline-block;
    position: absolute;
    height: 100rem;
    width: 110rem;
    left: -15rem;
    top: -16rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    height: 125rem;
    width: 140rem;
    left: -68rem;
    top: -28rem;
  }
`;

export const StyledShape2 = styled.div`
  display: none;
  pointer-events: none;

  @media (min-width: ${breakPoints.tablet}) {
    display: inline-block;
    position: absolute;
    height: 64.214rem;
    width: 60.177rem;
    right: -76rem;
    top: 0;
  }

  @media (min-width: ${breakPoints.desktop}) {
    left: 8.8rem;
    right: unset;
    top: -21.5rem;
  }
`;

export const StyledImage = styled(Image)`
  height: auto;
  width: 100%;
  top: 0rem;
  max-width: 50rem;

  @media (min-width: ${breakPoints.tablet}) {
    position: absolute;
    height: 53.63rem;
    width: 82.463rem;
    right: unset;
    left: 0;
    top: 4rem;
    max-width: 50rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    height: 53.63rem;
    width: 82.463rem;
    right: unset;
    left: 0;
    top: 6.5rem;
    max-width: 70rem;
  }

  @media (min-width: ${breakPoints.fullsize}) {
    left: -10.6rem;
    max-width: unset;
  }
`;
